import { useRouter } from "next/router";
import Link from "next/link";
import { memo, useState } from "react";
import Arrow from "public/assets/icons/next.svg";
import Locales from './locales/data'


const PhoneAsLink = ({
  isTitle = true,
  isAlignCenter = false,
  isFooter = false
}) => {
  const PHONES = ['+38 099 150 55 01', '+38 097 089 45 54']
  const { locale } = useRouter();
  const [selectedPhone, setSelectedPhone] = useState(0);

  const handlePhones = () => setSelectedPhone(selectedPhone === 0 ? 1 : 0);

  return (
    <div className={`${isAlignCenter ? "text-center" : "text-left"} w-full xl:w-[270px]`}>
      {isTitle && (
        <p className="uppercase opacity-50 tracking-wider pt-4 lg:pt-0">{Locales[locale]}</p>
      )}
      <div className={`inline-flex items-center ${isFooter ? "my-3" : "my-2"}`}>
        {isFooter && (
          <button
            type="button"
            className="text-white hover:text-secondary w-4 h-4 opacity-80 rotate-180"
            aria-label="Switch left"
            onClick={handlePhones}
          >
            <Arrow />
          </button>
        )}
        <Link href={`tel:${PHONES[selectedPhone]}`}>
          <a className={`text-2xl ${isFooter ? "min-w-[235px] px-2": "min-w-0 pr-2"}`}>{PHONES[selectedPhone]}</a>
        </Link>
        <button
          type="button"
          aria-label="Switch right"
          className="text-white hover:text-secondary w-4 h-4 opacity-80"
          onClick={handlePhones}
        >
          <Arrow />
        </button>
      </div>
    </div>
  )
};

export default memo(PhoneAsLink);